module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-rockinblocks","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/theme/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fac954cf958010a95d60e2471f63bfad"},
    },{
      plugin: require('../../../../plugins/gatsby-plugin-rockinblocks/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-tinacms/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/codebuild/output/src796361465/src/rockinblocks/node_modules/gatsby-tinacms-git","id":"bc2fa86f-5021-5d02-864f-ac2c889411ac","name":"gatsby-tinacms-git","version":"0.43.3","pluginOptions":{"plugins":[],"pathToContent":"/","defaultCommitMessage":"Edited with Rockin' Blocks! 🎸","defaultCommitName":"Rockin' Blocks","defaultCommitEmail":"git@rockinblocks.io","pushOnCommit":false},"nodeAPIs":["onCreateDevServer"],"browserAPIs":["onClientEntry"],"ssrAPIs":[]},{"resolve":"/codebuild/output/src796361465/src/rockinblocks/node_modules/gatsby-tinacms-remark","id":"5df20784-2a6f-5d83-a3bb-e31e9e5a5d06","name":"gatsby-tinacms-remark","version":"0.43.3","pluginOptions":{"plugins":[]},"nodeAPIs":["setFieldsOnGraphQLNodeType"],"browserAPIs":["onClientEntry"],"ssrAPIs":[]},{"resolve":"/codebuild/output/src796361465/src/rockinblocks/node_modules/gatsby-tinacms-json","id":"1c062b75-195d-5ce0-8163-a15d652f0df4","name":"gatsby-tinacms-json","version":"0.43.3","pluginOptions":{"plugins":[]},"nodeAPIs":["setFieldsOnGraphQLNodeType"],"browserAPIs":[],"ssrAPIs":[]}],"enabled":false,"sidebar":{"position":"displace"}},
    },{
      plugin: require('../../../../node_modules/gatsby-tinacms-git/gatsby-browser.js'),
      options: {"plugins":[],"pathToContent":"/","defaultCommitMessage":"Edited with Rockin' Blocks! 🎸","defaultCommitName":"Rockin' Blocks","defaultCommitEmail":"git@rockinblocks.io","pushOnCommit":false},
    },{
      plugin: require('../../../../node_modules/gatsby-tinacms-remark/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WFW54V5","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
