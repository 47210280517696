// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-content-docs-template-js": () => import("./../../../src/content/docs/template.js" /* webpackChunkName: "component---src-content-docs-template-js" */),
  "component---src-content-pages-template-js": () => import("./../../../src/content/pages/template.js" /* webpackChunkName: "component---src-content-pages-template-js" */),
  "component---src-content-posts-template-js": () => import("./../../../src/content/posts/template.js" /* webpackChunkName: "component---src-content-posts-template-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */)
}

